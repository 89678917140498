import React from "react";

export const Spanish = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    enableBackground="new 0 0 512 512"
    version="1.1"
    width={30}
    viewBox="0 0 512 512"
    xmlSpace="preserve"
  >
    <path fill="#FFDA44" d="M0 85.331H512V426.668H0z" />
    <g fill="#D80027">
      <path d="M0 85.331H512V199.106H0z" />
      <path d="M0 312.882H512V426.65700000000004H0z" />
    </g>
  </svg>
);

export const Portuguese = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    enableBackground="new 0 0 512 512"
    version="1.1"
    width={30}
    viewBox="0 0 512 512"
    xmlSpace="preserve"
  >
    <path fill="#6DA544" d="M0 85.331H512V426.668H0z" />
    <path
      fill="#FFDA44"
      d="M256 161.678L384 255.994 256 350.31 128 255.994z"
    />
    <circle cx="256" cy="255.994" r="53.894" fill="#F0F0F0" />
    <g fill="#0052B4">
      <path d="M229.052 252.626a90.66 90.66 0 00-26.93 4.07c.377 29.44 24.348 53.193 53.877 53.193 18.259 0 34.386-9.089 44.134-22.981-16.676-20.879-42.341-34.282-71.081-34.282zM308.895 266.32c.649-3.344 1-6.793 1-10.326 0-29.765-24.13-53.895-53.895-53.895-22.21 0-41.275 13.438-49.527 32.623a111.276 111.276 0 0122.579-2.307c31.304.001 59.622 13.012 79.843 33.905z" />
    </g>
  </svg>
);

export const English = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    enableBackground="new 0 0 512 512"
    version="1.1"
    width={30}
    viewBox="0 0 512 512"
    xmlSpace="preserve"
  >
    <path fill="#F0F0F0" d="M0 85.331H512V426.668H0z" />
    <g fill="#D80027">
      <path d="M0 127.994H512V170.65699999999998H0z" />
      <path d="M0 213.331H512V255.99399999999997H0z" />
      <path d="M0 298.657H512V341.32H0z" />
      <path d="M0 383.994H512V426.65700000000004H0z" />
    </g>
    <path fill="#2E52B2" d="M0 85.331H256V269.128H0z" />
    <g fill="#F0F0F0">
      <path d="M99.822 160.624L95.699 173.308 82.363 173.308 93.154 181.143 89.031 193.826 99.822 185.991 110.606 193.826 106.484 181.143 117.275 173.308 103.938 173.308z" />
      <path d="M103.938 219.08L99.822 206.397 95.699 219.08 82.363 219.08 93.154 226.916 89.031 239.599 99.822 231.763 110.606 239.599 106.484 226.916 117.275 219.08z" />
      <path d="M47.577 219.08L43.46 206.397 39.337 219.08 26.001 219.08 36.792 226.916 32.669 239.599 43.46 231.763 54.245 239.599 50.123 226.916 60.912 219.08z" />
      <path d="M43.46 160.624L39.337 173.308 26.001 173.308 36.792 181.143 32.669 193.826 43.46 185.991 54.245 193.826 50.123 181.143 60.912 173.308 47.577 173.308z" />
      <path d="M99.822 114.85L95.699 127.535 82.363 127.535 93.154 135.371 89.031 148.054 99.822 140.218 110.606 148.054 106.484 135.371 117.275 127.535 103.938 127.535z" />
      <path d="M43.46 114.85L39.337 127.535 26.001 127.535 36.792 135.371 32.669 148.054 43.46 140.218 54.245 148.054 50.123 135.371 60.912 127.535 47.577 127.535z" />
      <path d="M156.183 160.624L152.061 173.308 138.725 173.308 149.515 181.143 145.394 193.826 156.183 185.991 166.969 193.826 162.846 181.143 173.637 173.308 160.301 173.308z" />
      <path d="M160.301 219.08L156.183 206.397 152.061 219.08 138.725 219.08 149.515 226.916 145.394 239.599 156.183 231.763 166.969 239.599 162.846 226.916 173.637 219.08z" />
      <path d="M216.663 219.08L212.546 206.397 208.423 219.08 195.088 219.08 205.877 226.916 201.755 239.599 212.546 231.763 223.331 239.599 219.208 226.916 229.999 219.08z" />
      <path d="M212.546 160.624L208.423 173.308 195.088 173.308 205.877 181.143 201.755 193.826 212.546 185.991 223.331 193.826 219.208 181.143 229.999 173.308 216.663 173.308z" />
      <path d="M156.183 114.85L152.061 127.535 138.725 127.535 149.515 135.371 145.394 148.054 156.183 140.218 166.969 148.054 162.846 135.371 173.637 127.535 160.301 127.535z" />
      <path d="M212.546 114.85L208.423 127.535 195.088 127.535 205.877 135.371 201.755 148.054 212.546 140.218 223.331 148.054 219.208 135.371 229.999 127.535 216.663 127.535z" />
    </g>
  </svg>
);